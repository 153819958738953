@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --light: #0240bb;
  --dark: rgba(245, 245, 245, 1);
  --green: #060855;
}

body.ContactForm {
  font-family: Trebuchet MS;
  background: var(--dark);
  line-height: 26px;
  margin: 0;
}

h1 {
  color: var(--green);
}

form {
  background: var(--dark);
  padding-top: 10px;
}

.formRow {
  margin: 10px 0 20px 0;
}

.formInput {
  padding: 15px 10px;
  background-color: var(--dark);
  box-shadow: 0 0 0 2px var(--green);
  border: none;
  color: var(--green);
}

.formInput:focus {
  background-color: var(--dark);
  outline: none;
  box-shadow: 0 0 0 2px var(--green);
  color: var(--light);
}

.formInput::placeholder {
  color: var(--green);
  opacity: 1;
}

.formInput:focus::placeholder {
  color: var(--light);
  opacity: 1;
}

.errorMessage {
  color: #f45532;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

#notifyToast {
  color: #fff;
  border-radius: 6px;
  width: 200px;
  margin-left: 75px;
  background-color: var(--green);
}

.submit-btn {
  background-color: var(--dark);
  color: var(--green) !important;
  padding: 15px 50px;
  border: 2px solid var(--green);
  border-radius: 10px;
  margin-bottom: 25px;
}

.submit-btn:hover {
  background-color: var(--green);
  color: var(--dark) !important;
  border: 2px solid var(--green);
}

.submit-btn:disabled:hover {
  background-color: var(--dark);
  color: var(--green) !important;
  cursor: wait;
}

@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }
}
